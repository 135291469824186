import React from 'react';
import classNames from 'classnames';
import { BrProps } from '@bloomreach/react-sdk';
import { ImageSet } from '@bloomreach/spa-sdk';
import {
  Accordion as MaterialAccordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core/';
import { ReactComponent as CaretDownIcon } from '../../assets/images/arrow-drop-down.svg';
import { uniqueKey, getUrlFromImage } from '../utils';
import './Accordion.scss';

interface AccordionParameters {
  variant?: 'faq' | 'logoAccordion';
  headingId?: string;
}

interface AccordionModel {
  componentParameterMap: AccordionParameters;
  document: import('@bloomreach/spa-sdk').Reference;
}

export const Accordion: React.FC<BrProps> = ({ component, page }: BrProps) => {
  const {
    componentParameterMap: { variant = 'faq', headingId = '' },
    document: documentRef,
  } = component.getModels<AccordionModel>();

  if (!documentRef) {
    return null;
  }

  const accordionDocument = page?.getContent(documentRef);
  const { groupTitle, accordionContent } = accordionDocument?.getData<AccordionData>() || {
    groupTitle: '',
    accordionContent: [] as AccordionContent[],
  };

  const accordionClassnames = classNames(
    'cvc-accordion',
    variant === 'logoAccordion' ? 'cvc-accordion__logo-variant' : ''
  );
  const hasLogo = variant === 'logoAccordion';

  const getImageSrc = (imageLinkRef: any): string => {
    return getUrlFromImage(page?.getContent<ImageSet>(imageLinkRef));
  };

  return (
    <div className={accordionClassnames}>
      <div className="cvc-accordion__content">
        <div className="cvc-accordion__header">
          {groupTitle && <h2 {...(headingId ? { id: headingId } : {})}>{groupTitle}</h2>}
        </div>
        <div className="cvc-accordion__body">
          {accordionContent.map(({ boolean, detailContent, headerContent, headerLogo }, index) => (
            <MaterialAccordion defaultExpanded={boolean} key={`accordion_${uniqueKey()}`}>
              <AccordionSummary
                expandIcon={<CaretDownIcon />}
                aria-controls={`panel-content-${index}`}
                id={`panel-header-${index}`}
              >
                {hasLogo && (
                  <div className="cvc-accordion__accordion-logo__container">
                    <img
                      className="cvc-accordion__accordion-logo__image"
                      alt={headerLogo?.alt}
                      src={getImageSrc(headerLogo?.imagelink)}
                    />
                  </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: headerContent?.value }} />
              </AccordionSummary>
              <AccordionDetails>
                <div dangerouslySetInnerHTML={{ __html: detailContent?.value }} />
              </AccordionDetails>
            </MaterialAccordion>
          ))}
        </div>
      </div>
    </div>
  );
};
