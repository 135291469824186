import React, { useContext, useCallback, useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import { Col, Row, Container } from 'react-bootstrap';
import { ResolverLink } from 'components/shared';
import { initAllFavoritesForCurrentUser, condenseRetailerHours } from 'components/utils';

import { scroller } from 'react-scroll';

import { ReactComponent as LocationIcon } from '../../../assets/images/cavcohomes/location-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/images/cavcohomes/phone-icon.svg';
import { ReactComponent as ClockIcon } from '../../../assets/images/cavcohomes/clock-icon.svg';
import './RetailerHeaderInfo.scss';
import { ModalLaunchContext } from '../../hooks/ModalLaunch.context';
import { FavoriteIcon } from '../../shared/Icons/FavoriteIcon';
import { getCookie } from '../../hooks/cookie';

export interface RetailerHours {
  day: string;
  hours: string;
}

export interface RetailerHeaderInfoProps {
  name: string;
  latitude: number;
  longitude: number;
  location: string;
  phone: string;
  locationType: string;
  hourList: RetailerHours[];
  brands?: any;
  address: any;
  address2: any;
  city: any;
  state: any;
  zipcode: any;
  isHomepage?: boolean;
  showHours: boolean;
  requestInfoLink?: string;
  formIsModal?: string;
  isDetailPage?: boolean;
  retailerHeaderLabels?: any;
  brandTitle?: string;
  loadFavoritesUrl?: string;
  favoriteBaseUrl?: string;
  locationID?: string;
  myAccountConfig?: any;
  websiteUrl?: string;
  isRequestInfoStandAlone?: boolean;
}

const RetailerHeaderInfo = ({
  name,
  latitude,
  longitude,
  location,
  phone,
  locationType,
  hourList,
  address,
  address2,
  city,
  state,
  zipcode,
  isHomepage,
  showHours,
  brands,
  requestInfoLink = 'fp-request-info-section',
  formIsModal,
  isDetailPage,
  retailerHeaderLabels,
  brandTitle,
  loadFavoritesUrl,
  favoriteBaseUrl,
  locationID,
  myAccountConfig,
  websiteUrl = '',
  isRequestInfoStandAlone = false,
}: RetailerHeaderInfoProps) => {
  const { setGlobalModal } = useContext<any>(ModalLaunchContext);

  const uniqueBrands = new Set();
  const brandLogos =
    brands &&
    brands?.filter((element: { alt: string }) => {
      const isDuplicate = uniqueBrands.has(element.alt);
      uniqueBrands.add(element.alt);
      return !isDuplicate && element.alt !== undefined;
    });

  const handleClickRequest = () => {
    if (formIsModal === 'true') {
      setGlobalModal('retailerRequestInfoForm');
    } else {
      scroller.scrollTo(requestInfoLink, {
        duration: 1000,
        smooth: true,
        offset: -150,
      });
    }
  };

  const userId = getCookie('cavcouserid');

  const loadFavoritesList = useCallback(async (loadFavoriteUrl: string) => {
    await initAllFavoritesForCurrentUser(loadFavoriteUrl);
  }, []);

  useEffect(() => {
    if (myAccountConfig?.cavcoFavoriteUrl && userId)
      loadFavoritesList(`${myAccountConfig.cavcoFavoriteUrl}`);
  }, [loadFavoritesList, myAccountConfig, userId]);

  // console.log('retailerHeaderLabels', retailerHeaderLabels);

  function locationTypeHandler() {
    if (locationType === 'Community') {
      return <span>{retailerHeaderLabels?.community}</span>;
    }
    if (locationType === 'Retirement Community') {
      return <span>{retailerHeaderLabels?.retirement_community}</span>;
    }
    if (locationType === 'Retailer') {
      return <span>{retailerHeaderLabels?.retailer}</span>;
    }
    if (locationType === 'Park Model Retailer') {
      return <span>{retailerHeaderLabels?.park_model_retailer}</span>;
    }
    return <span>{retailerHeaderLabels?.title_type_homepage}</span>;
  }

  if (isHomepage) {
    return (
      <div className="cvc-cavco-header-retailer__request-info">
        <Row className="cvc-cavco-header-retailer__request-info--top">
          <Col className="request-info-section request-info-section--left" md={9} xs={12}>
            {locationTypeHandler()}
            <h1 itemProp="name">{name}</h1>
            <Row className="request-info-section--wrapper">
              <div className="location-section">
                <ResolverLink
                  className="location-link"
                  itemProp="hasMap"
                  href={`https://maps.google.com/?q=${latitude},${longitude}`}
                  target="_blank"
                >
                  <LocationIcon className="location-link__icon" />

                  <span>{name}</span>
                </ResolverLink>
                <div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                  <div itemProp="streetAddress">
                    <p>{address}</p>
                    {address2 && <p>{address2}</p>}
                  </div>
                  <p>
                    <span itemProp="addressLocality">{city}</span>,{` `}
                    <span itemProp="addressRegion">{state}</span>
                    {` `}
                    <span itemProp="postalCode">{zipcode}</span>
                  </p>
                </div>
              </div>
              <div className="hours-section">
                <ClockIcon className="clock-link__icon" />
                <table>
                  <tbody>
                    {condenseRetailerHours(hourList)?.map(({ day, hours }) => (
                      <tr key={`cell-${day}`}>
                        <td>{day}</td>
                        <td className="time">{hours}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="phone-section">
                <a className="phone-link" href={`tel:${phone}`}>
                  <PhoneIcon className="phone-link__icon" />
                  <span itemProp="telephone">{phone}</span>
                </a>
              </div>
              <div className="location-detail-mobile">
                <div className="phone-section mobile-only">
                  <a className="phone-link" href={`tel:${phone}`}>
                    <PhoneIcon className="phone-link__icon" />
                    <span>{phone}</span>
                  </a>
                </div>
                <div className="hours-section mobile-only">
                  <ClockIcon className="clock-link__icon" />
                  <table>
                    <tbody>
                      {condenseRetailerHours(hourList)?.map(({ day, hours }) => (
                        <tr key={`cell-${day}`}>
                          <td>{day}</td>
                          <td className="time">{hours}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Row>
          </Col>
          <Col className="request-info-section request-info-section--right" md={3} xs={12}>
            {!isRequestInfoStandAlone && (
              <Button className="request-info-button" onClick={() => handleClickRequest()}>
                Request Info
              </Button>
            )}
            <div className="d-flex">
              <FavoriteIcon
                classes="floorplan-result__favorite floorplan-save-favorites-icon"
                type="our_retailers"
                baseUrl={favoriteBaseUrl}
                loadFavoriteUrl={loadFavoritesUrl}
                isCavcoHomesVariant
                id={locationID}
                myAccountConfig={myAccountConfig}
                websiteUrl={websiteUrl}
              />
              <span>Add to favorites</span>
            </div>
          </Col>
        </Row>
        <Row className="cvc-cavco-header-retailer__request-info--bottom">
          <div className="bottom-label">{brandTitle}</div>
          <div className="bottom-content">
            {brandLogos &&
              brandLogos?.map(({ src, alt }: any) => (
                <div
                  key={`logo_${alt}`}
                  className="cvc-cavco-header-retailer__request-info--brands"
                >
                  <img src={src} alt={alt} />
                </div>
              ))}
          </div>
        </Row>
      </div>
    );
  }
  return (
    <div className="cvc-cavco-header-retailer__request-info">
      <Container>
        <h5>{locationTypeHandler()}</h5>
        <Row>
          <Col md={10} xs={12}>
            <ResolverLink id="retailer-header-link" href={websiteUrl} target="">
              <h3 id="retailer-header" itemProp="name">
                {name}
              </h3>
            </ResolverLink>
            <Grid container className="location-section">
              <Grid className="flex-mobile" item xs="auto" md={showHours ? 4 : 'auto'}>
                <span className="location-link__mobile">{location}</span>
                <ResolverLink
                  className="location-link"
                  itemProp="hasMap"
                  href={`https://maps.google.com/?q=${latitude},${longitude}`}
                  target="_blank"
                >
                  <LocationIcon className="location-link__icon" />
                  <span>{location}</span>
                </ResolverLink>
              </Grid>
              <Grid item xs="auto" md={showHours ? 4 : 'auto'}>
                <a className="phone-link" href={`tel:${phone}`}>
                  <PhoneIcon className="phone-link__icon" />
                  <span itemProp="telephone">{phone}</span>
                </a>
              </Grid>
              {showHours && (
                <Grid item xs={12} md={4}>
                  {hourList?.length && (
                    <ul className="cvc-cavco-header-retailer__hours">
                      <li className="clock-icon">
                        <ClockIcon />
                      </li>
                      {hourList?.map(({ day, hours: hour }) => (
                        <li>
                          <strong>{day}&nbsp;</strong>
                          <span>{hour}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </Grid>
              )}
            </Grid>
          </Col>
          {!isDetailPage && (
            <Col className="request-info-section" md={2} xs={12}>
              {!isRequestInfoStandAlone && (
                <Button
                  className="request-info-button"
                  onClick={() => setGlobalModal('retailerRequestInfoForm')}
                >
                  Request Info
                </Button>
              )}
              <div className="d-flex">
                <FavoriteIcon
                  classes="floorplan-result__favorite floorplan-save-favorites-icon"
                  type="our_retailers"
                  isCavcoHomesVariant
                  baseUrl={favoriteBaseUrl}
                  loadFavoriteUrl={loadFavoritesUrl}
                  myAccountConfig={myAccountConfig}
                  id={locationID}
                />
                <span>Add to favorites</span>
              </div>
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default RetailerHeaderInfo;
