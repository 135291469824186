import React from 'react';
import { BrProps } from '@bloomreach/react-sdk';
import './CavcoRetailerHeader.scss';
import { formatPhoneNumber, getRetailerData, isRetailerHomePage } from 'components/utils';

import { getImage } from 'components';
import RetailerHeaderInfo, { RetailerHeaderInfoProps, RetailerHours } from './RetailerHeaderInfo';

const getLocationType = (locationTypes: any, key: string) =>
  locationTypes ? locationTypes[key] : '';

const generateHours = (retailerData: RetailerData | null): RetailerHours[] => {
  if (!retailerData) {
    return [];
  }
  const mapDays: { [key: string]: keyof RetailerData } = {
    Mon: 'hoursOpMonday',
    Tue: 'hoursOpTuesday',
    Wed: 'hoursOpWednesday',
    Thu: 'hoursOpThursday',
    Fri: 'hoursOpFriday',
    Sat: 'hoursOpSaturday',
    Sun: 'hoursOpSunday',
  };
  return Object.keys(mapDays).map((key) => ({
    day: key,
    hours: retailerData[mapDays[key]],
  }));
};

export function CavcoRetailerHeader({ page, component }: BrProps) {
  const {
    header: documentRef,
    componentParameterMap,
    'location-types': locationTypes,
    retailerBrandLogos: cavcoBrands,
    retailerwebtypelogos: webTypeLogos,
    retailerHeaderLabels,
    'administration/value-list/brands': brand_ids,
    myAccountConfig,
    siteMapParams,
  } = component.getModels();

  const document = documentRef && page.getContent(documentRef);
  if (!document) {
    return null;
  }

  const retailerData = getRetailerData(page);
  const homepageInternalImage =
    retailerData && page.getContent(retailerData?.featuredPhotos?.image);
  const homepageImage = getImage('', retailerData?.featuredPhotos, homepageInternalImage);
  let brandLogos =
    retailerData?.brands &&
    retailerData?.brands?.map((brand) => ({ alt: brand_ids[brand], src: cavcoBrands[brand] }));

  if ((!brandLogos || brandLogos.length === 0) && retailerData && retailerData.webTypes) {
    const webTypes: string[] = retailerData?.webTypes.split(',');
    brandLogos = webTypes.map((webType) => ({
      alt: brand_ids[webType],
      src: webTypeLogos[webType],
    }));
  }
  const brandTitle = cavcoBrands?.title;
  const locationID = retailerData?.locationID;
  // const isFavorite = retailerData?.isFavorite;
  //  const isFavorite = locationID ? isFavoriteForUser(locationID) : retailerData?.isFavorite;
  //  const siteUrl = retailerData?._links.site.href;

  //  const setFavoriteUrl = `${
  //    myAccountConfig?.favoriteBaseUrl
  //  }/our_retailers/${locationID}/${!isFavorite}?&websiteUrl=${siteUrl}`;

  // const websiteUrl = retailerData?.links.site.href;
  //const retailerDataComponent: any = page.getComponent('retailer-header-content', 'retailer-data');
  const websiteUrl = retailerData?.retailerPath;
  const retailerHeaderInfoProps: RetailerHeaderInfoProps = {
    name: retailerData?.name || '',
    location: `${retailerData?.city}, ${retailerData?.state}`,
    latitude: retailerData?.latitude || 0,
    longitude: retailerData?.longitude || 0,
    locationType: retailerData?.locationtypes?.length
      ? getLocationType(locationTypes, retailerData?.locationtypes[0])
      : '',
    phone: formatPhoneNumber(retailerData?.phone),
    hourList: generateHours(retailerData),
    address: retailerData?.address1,
    address2: retailerData?.address2,
    city: retailerData?.city,
    state: retailerData?.state,
    zipcode: retailerData?.zip,
    isHomepage: isRetailerHomePage(page),
    showHours: componentParameterMap?.showHours === 'on',
    brands: brandLogos,
    requestInfoLink: componentParameterMap?.requestInfoJumpLink,
    formIsModal: componentParameterMap?.isModal,
    isDetailPage: componentParameterMap?.template === 'cavco-floorplans-detail',
    retailerHeaderLabels,
    brandTitle,
    loadFavoritesUrl: myAccountConfig.cavcoFavoriteUrl,
    favoriteBaseUrl: myAccountConfig.favoriteBaseUrl,
    locationID,
    myAccountConfig,
    websiteUrl,
    isRequestInfoStandAlone: siteMapParams?.isRequestInfoStandAlone === 'true',
  };

  if (isRetailerHomePage(page)) {
    return (
      <div
        className={`cvc-cavco-header-retailer is-homepage ${
          homepageImage ? 'with-image' : 'full-width'
        }`}
        itemScope
        itemType="https://schema.org/LocalBusiness"
      >
        <div className="cvc-cavco-header-retailer__banner">
          <div className="cvc-cavco-header-retailer__banner--image">{homepageImage}</div>

          <RetailerHeaderInfo {...retailerHeaderInfoProps} />
        </div>
      </div>
    );
  }

  return (
    <div
      className="cvc-cavco-header-retailer"
      itemScope
      itemType="https://schema.org/LocalBusiness"
    >
      <RetailerHeaderInfo {...retailerHeaderInfoProps} />
    </div>
  );
}
